@import './solvcrm-colors.scss';
@import './xl-financial-colors.scss';


// Themes
$themes: (
    solvcrm: (secondary:$solvcrm-secondary,
        secondary25:$solvcrm-secondary-25,
        secondary50:$solvcrm-secondary-50,
        secondary100:$solvcrm-secondary-100,
        secondary200:$solvcrm-secondary-200,
        secondary300:$solvcrm-secondary-300,
        secondary400:$solvcrm-secondary-400,
        secondary500:$solvcrm-secondary-500,
        secondary600:$solvcrm-secondary-600,
        secondary700:$solvcrm-secondary-700,
        secondary800:$solvcrm-secondary-800,
        secondary900:$solvcrm-secondary-900,
        secondary950:$solvcrm-secondary-950,
        systemWhite:$solvcrm-system-white,
        systemBlack:$solvcrm-system-black,
        systemTextPrimary:$solvcrm-system-text-primary,
        systemTextSecondary:$solvcrm-system-text-secondary,
        systemTextTertiary:$solvcrm-system-text-tertiary,
        systemBorder:$solvcrm-system-border,
        systemBorderLight:$solvcrm-system-border-light,
        primary:$solvcrm-primary,
        primary25:$solvcrm-primary-25,
        primary50:$solvcrm-primary-50,
        primary100:$solvcrm-primary-100,
        primary200:$solvcrm-primary-200,
        primary300:$solvcrm-primary-300,
        primary400:$solvcrm-primary-400,
        primary500:$solvcrm-primary-500,
        primary600:$solvcrm-primary-600,
        primary700:$solvcrm-primary-700,
        primary800:$solvcrm-primary-800,
        primary900:$solvcrm-primary-900,
        neutral25:$solvcrm-neutral-25,
        neutral50:$solvcrm-neutral-50,
        neutral100:$solvcrm-neutral-100,
        neutral200:$solvcrm-neutral-200,
        neutral300:$solvcrm-neutral-300,
        neutral400:$solvcrm-neutral-400,
        neutral500:$solvcrm-neutral-500,
        neutral600:$solvcrm-neutral-600,
        neutral700:$solvcrm-neutral-700,
        neutral800:$solvcrm-neutral-800,
        neutral900:$solvcrm-neutral-900,
        warning:$solvcrm-warning,
        warning25:$solvcrm-warning-25,
        warning50:$solvcrm-warning-50,
        warning100:$solvcrm-warning-100,
        warning200:$solvcrm-warning-200,
        warning300:$solvcrm-warning-300,
        warning400:$solvcrm-warning-400,
        warning500:$solvcrm-warning-500,
        warning600:$solvcrm-warning-600,
        warning700:$solvcrm-warning-700,
        warning800:$solvcrm-warning-800,
        warning900:$solvcrm-warning-900,
        success:$solvcrm-success,
        success25:$solvcrm-success-25,
        success50:$solvcrm-success-50,
        success100:$solvcrm-success-100,
        success200:$solvcrm-success-200,
        success300:$solvcrm-success-300,
        success400:$solvcrm-success-400,
        success500:$solvcrm-success-500,
        success600:$solvcrm-success-600,
        success700:$solvcrm-success-700,
        success800:$solvcrm-success-800,
        success900:$solvcrm-success-900,
        dangerError:$solvcrm-danger-error,
        dangerError25:$solvcrm-danger-error-25,
        dangerError50:$solvcrm-danger-error-50,
        dangerError100:$solvcrm-danger-error-100,
        dangerError200:$solvcrm-danger-error-200,
        dangerError300:$solvcrm-danger-error-300,
        dangerError400:$solvcrm-danger-error-400,
        dangerError500:$solvcrm-danger-error-500,
        dangerError600:$solvcrm-danger-error-600,
        dangerError700:$solvcrm-danger-error-700,
        dangerError800:$solvcrm-danger-error-800,
        dangerError900:$solvcrm-danger-error-900,
        bodyBackground:$solvcrm-system-white,
        cardBackground:$solvcrm-system-white,
        wrapperBackground:$solvcrm-neutral-25,
        loaderBackground:rgba(255, 255, 255, 0.72),
        fontFamily:('proxima-nova', sans-serif),
        statusBadgeErrorBackgroundColor: $solvcrm-danger-error-50,
        statusBadgeErrorTextColor: $solvcrm-danger-error-800,
        statusBadgeWarningBackgroundColor: $solvcrm-warning-50,
        statusBadgeWarningTextColor: $solvcrm-warning-800,
        statusBadgeSuccessBackgroundColor: $solvcrm-success-50,
        statusBadgeSuccessTextColor: $solvcrm-success-800,
        statusBadgeDefaultBackgroundColor: $solvcrm-neutral-25,
        statusBadgeDefaultTextColor: $solvcrm-system-text-secondary,
        statusBadgePrimaryBackgroundColor: $solvcrm-primary-600,
        statusBadgePrimaryTextColor: $solvcrm-system-white,
        statusBadgeDarkBackgroundColor: $solvcrm-neutral-400,
        statusBadgeDarkTextColor: $solvcrm-system-white,
        easyEditSaveBtnTextColor:$solvcrm-system-white,
        easyEditSaveBtnBackgroundColor:$solvcrm-primary-600,
        easyEditSaveBtnHoverBackgroundColor: $solvcrm-primary-700,
        easyEditCancelBtnTextColor:$solvcrm-system-text-secondary,
        easyEditCancelBtnBackgroundColor:$solvcrm-system-white,
        easyEditCancelBtnHoverBackgroundColor:$solvcrm-neutral-50,
        secondaryBackground:$solvcrm-neutral-25,
        sidebarBackground:$solvcrm-secondary-900,
        checkBtnTextColor:$solvcrm-neutral-200,
        checkBtnBorderColor: $solvcrm-system-border,
        checkBtnBackgroundColor: $solvcrm-system-white,
        checkBtnHoverTextColor:$solvcrm-primary-700,
        checkBtnHoverBorderColor: $solvcrm-primary-700,
        checkBtnHoverBackgroundColor: $solvcrm-primary-50,
        btnTextColor:$solvcrm-system-white,
        loginCutImage:'../images/login-cut.svg',
        dialogBackdropColor:rgba(0, 0, 0, 0.5),
        btnFocusRing:$solvcrm-primary-700,
        btnPrimaryBackground: $solvcrm-primary-700,
        btnPriamryTextColor: $solvcrm-system-white,
        btnHoverPrimaryBackground: $solvcrm-primary-800,
        btnSecondaryBackgroundColor: $solvcrm-neutral-50,
        btnHoverSecondaryBackgroundColor: $solvcrm-neutral-100,
        btnSecondaryTextColor: $solvcrm-neutral-900,
        dialogBackgroundColor:$solvcrm-system-white,
        shadow1:0 2px 4px rgba(0, 0, 0, 0.14),
        shadow2:0 4px 8px rgba(0, 0, 0, 0.14),
        shadow3:(0 1px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.16)),
        shadow4:(0 1px 8px rgba(0, 0, 0, 0.1), 0 16px 32px rgba(0, 0, 0, 0.16)),
        shadow5:(0 2px 12px rgba(0, 0, 0, 0.1), 0 32px 64px rgba(0, 0, 0, 0.2)),
    ),
    xl-financial: (secondary:$xl-financial-secondary,
        secondary25:$xl-financial-secondary-25,
        secondary50:$xl-financial-secondary-50,
        secondary100:$xl-financial-secondary-100,
        secondary200:$xl-financial-secondary-200,
        secondary300:$xl-financial-secondary-300,
        secondary400:$xl-financial-secondary-400,
        secondary500:$xl-financial-secondary-500,
        secondary600:$xl-financial-secondary-600,
        secondary700:$xl-financial-secondary-700,
        secondary800:$xl-financial-secondary-800,
        secondary900:$xl-financial-secondary-900,
        secondary950:$xl-financial-secondary-950,
        systemWhite:$xl-financial-system-white,
        systemBlack:$xl-financial-system-black,
        systemTextPrimary:$xl-financial-system-text-primary,
        systemTextSecondary:$xl-financial-system-text-secondary,
        systemTextTertiary:$xl-financial-system-text-tertiary,
        systemBorder:$xl-financial-system-border,
        systemBorderLight:$xl-financial-system-border-light,
        primary:$xl-financial-primary,
        primary25:$xl-financial-primary-25,
        primary50:$xl-financial-primary-50,
        primary100:$xl-financial-primary-100,
        primary200:$xl-financial-primary-200,
        primary300:$xl-financial-primary-300,
        primary400:$xl-financial-primary-400,
        primary500:$xl-financial-primary-500,
        primary600:$xl-financial-primary-600,
        primary700:$xl-financial-primary-700,
        primary800:$xl-financial-primary-800,
        primary900:$xl-financial-primary-900,
        neutral25:$xl-financial-neutral-25,
        neutral50:$xl-financial-neutral-50,
        neutral100:$xl-financial-neutral-100,
        neutral200:$xl-financial-neutral-200,
        neutral300:$xl-financial-neutral-300,
        neutral400:$xl-financial-neutral-400,
        neutral500:$xl-financial-neutral-500,
        neutral600:$xl-financial-neutral-600,
        neutral700:$xl-financial-neutral-700,
        neutral800:$xl-financial-neutral-800,
        neutral900:$xl-financial-neutral-900,
        warning:$xl-financial-warning,
        warning25:$xl-financial-warning-25,
        warning50:$xl-financial-warning-50,
        warning100:$xl-financial-warning-100,
        warning200:$xl-financial-warning-200,
        warning300:$xl-financial-warning-300,
        warning400:$xl-financial-warning-400,
        warning500:$xl-financial-warning-500,
        warning600:$xl-financial-warning-600,
        warning700:$xl-financial-warning-700,
        warning800:$xl-financial-warning-800,
        warning900:$xl-financial-warning-900,
        success:$xl-financial-success,
        success25:$xl-financial-success-25,
        success50:$xl-financial-success-50,
        success100:$xl-financial-success-100,
        success200:$xl-financial-success-200,
        success300:$xl-financial-success-300,
        success400:$xl-financial-success-400,
        success500:$xl-financial-success-500,
        success600:$xl-financial-success-600,
        success700:$xl-financial-success-700,
        success800:$xl-financial-success-800,
        success900:$xl-financial-success-900,
        dangerError:$xl-financial-danger-error,
        dangerError25:$xl-financial-danger-error-25,
        dangerError50:$xl-financial-danger-error-50,
        dangerError100:$xl-financial-danger-error-100,
        dangerError200:$xl-financial-danger-error-200,
        dangerError300:$xl-financial-danger-error-300,
        dangerError400:$xl-financial-danger-error-400,
        dangerError500:$xl-financial-danger-error-500,
        dangerError600:$xl-financial-danger-error-600,
        dangerError700:$xl-financial-danger-error-700,
        dangerError800:$xl-financial-danger-error-800,
        dangerError900:$xl-financial-danger-error-900,
        bodyBackground:$xl-financial-neutral-950,
        cardBackground:$xl-financial-neutral-900,
        wrapperBackground:$xl-financial-neutral-950,
        loaderBackground:rgba(0, 0, 0, 0.72),
        fontFamily:('DM Sans', sans-serif),
        statusBadgeErrorBackgroundColor: $xl-financial-danger-error-900,
        statusBadgeErrorTextColor: $xl-financial-danger-error-200,
        statusBadgeWarningBackgroundColor: $xl-financial-warning-900,
        statusBadgeWarningTextColor: $xl-financial-warning-200,
        statusBadgeSuccessBackgroundColor: $xl-financial-success-900,
        statusBadgeSuccessTextColor: $xl-financial-success-200,
        statusBadgeDefaultBackgroundColor: $xl-financial-neutral-700,
        statusBadgeDefaultTextColor: $xl-financial-neutral-50,
        statusBadgePrimaryBackgroundColor: $xl-financial-primary-300,
        statusBadgePrimaryTextColor: $xl-financial-primary-900,
        statusBadgeDarkBackgroundColor: $xl-financial-neutral-500,
        statusBadgeDarkTextColor: $xl-financial-neutral-900,
        easyEditSaveBtnTextColor:$xl-financial-primary-900,
        easyEditSaveBtnBackgroundColor:$xl-financial-primary-400,
        easyEditSaveBtnHoverBackgroundColor: $xl-financial-primary-300,
        easyEditCancelBtnTextColor:$xl-financial-system-text-primary,
        easyEditCancelBtnBackgroundColor:$xl-financial-neutral-800,
        easyEditCancelBtnHoverBackgroundColor:$xl-financial-neutral-700,
        secondaryBackground:$xl-financial-neutral-800,
        sidebarBackground:$xl-financial-neutral-900,
        checkBtnTextColor:$xl-financial-system-text-tertiary,
        checkBtnBorderColor: $xl-financial-system-border,
        checkBtnBackgroundColor: $xl-financial-neutral-900,
        checkBtnHoverTextColor:$xl-financial-primary-300,
        checkBtnHoverBorderColor: $xl-financial-primary-300,
        checkBtnHoverBackgroundColor: $xl-financial-primary-900,
        btnTextColor:$xl-financial-primary-900,
        loginCutImage:'../images/login-cut-dark.svg',
        dialogBackdropColor:rgba(0, 0, 0, 0.64),
        btnFocusRing:$xl-financial-primary-400,
        btnPrimaryBackground: $xl-financial-primary-400,
        btnPriamryTextColor: $xl-financial-primary-900,
        btnHoverPrimaryBackground: $xl-financial-primary-300,
        btnSecondaryBackgroundColor: $xl-financial-neutral-800,
        btnHoverSecondaryBackgroundColor: $xl-financial-neutral-700,
        btnSecondaryTextColor: $xl-financial-neutral-50,
        dialogBackgroundColor:$xl-financial-neutral-900,
        shadow1:0 2px 4px rgba(0, 0, 0, 0.28),
        shadow2:0 4px 8px rgba(0, 0, 0, 0.28),
        shadow3:(0 1px 4px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.32)),
        shadow4:(0 1px 8px rgba(0, 0, 0, 0.2), 0 16px 32px rgba(0, 0, 0, 0.32)),
        shadow5:(0 2px 12px rgba(0, 0, 0, 0.2), 0 32px 64px rgba(0, 0, 0, 0.4)),
    )
);


// Theme Logic
@mixin theme($themes: $themes) {
    @each $theme, $var in $themes {
        .theme-#{$theme} & {
            @content($var);
        }
    }
}


// Typography
@mixin display-large() {
    font-size: 57px;
    line-height: 64px;
}

@mixin display-medium() {
    font-size: 45px;
    line-height: 52px;
}

@mixin display-small() {
    font-size: 36px;
    line-height: 44px;
}

@mixin display-small-bold() {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
}

@mixin headline-large() {
    font-size: 32px;
    line-height: 40px;
}

@mixin headline-medium() {
    font-size: 28px;
    line-height: 36px;
}

@mixin headline-small() {
    font-size: 24px;
    line-height: 32px;
}

@mixin headline-small-bold() {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}

@mixin headline-medium-bold() {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
}

@mixin headline-medium-semibold() {
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
}

@mixin title-large() {
    font-size: 22px;
    line-height: 28px;
}

@mixin title-medium() {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.0015em;
}

@mixin title-small() {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.001em;
}

@mixin title-medium-bold() {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.0015em;
}

@mixin title-large-bold() {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
}

@mixin title-large-semibold() {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
}

@mixin title-small-bold() {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.001em;
}

@mixin title-medium-large-semibold() {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
}

@mixin title-small-semibold() {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.001em;
}

@mixin label-large() {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.001em;
}

@mixin label-medium() {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.005em;
}

@mixin label-medium-semibold() {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.005em;
}

@mixin label-small() {
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.005em;
}

@mixin label-large-bold() {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.001em;
}

@mixin label-small-bold() {
    font-size: 11px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.005em;
}

@mixin label-medium-bold() {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.005em;
}

@mixin label-small-semibold() {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.004em;
}

@mixin body-large() {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0015em;
}

@mixin body-medium() {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0025em;
}

@mixin body-small() {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.004em;
}

@mixin body-large-semibold() {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.0015em;
}

@mixin body-medium-semibold() {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.0025em;
}

@mixin body-large-bold() {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.0015em;
}

@mixin body-medium-medium() {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.0025em;
}

@mixin body-large-medium() {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.0015em;
}

@mixin body-medium-bold() {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.0025em;
}

@mixin text-truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
}